<template>
  <div class="rb-view">概况</div>
</template>

<script>
    import {tokenCheck} from "../api/sys/user";

    export default {
        name: "dashboard",
        beforeCreate() {
            tokenCheck()
        }
    }
</script>

<style scoped>

</style>
